@tailwind base;
@tailwind components;
@tailwind utilities;

/*fonts*/
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


/* Initial state before elements are visible */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  /* Final state when elements are visible */
  .fade-in-visible {
    opacity: 1;
    transform: translateY(0);
  }
  